@import 'styles/variables.scss';

.loading {
    position:fixed;
    top:50%; left:50%; 
    transform:translate(-50%, -50%);

    &__circle {
        display: block;
        position: relative;
        top: 50%;
        left: 50%;
        height: 50px;
        width: 50px;
        margin: -25px 0 0 -25px;
        border: 4px rgba(0, 0, 0, 0.25) solid;
        border-top: 4px black solid;
        border-radius: 50%;
        animation: spin2 1s infinite linear;
    }
    &__text {
        margin-top:10px;
    }

}
  
.loading {
    &__loginchk {
        position:absolute;
        top:0; left:0;
    }

}


  @keyframes spin2 {
    from {
        transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
        transform: rotate(359deg);
    }
  }