@mixin bgImg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin btnNormal {
  font-size: 16px;
  color: #ffffff;
  background: $color_store_theme;
  border: 2px solid $color_store_theme;
  cursor: pointer;
  outline: none;
  transition: background 300ms;
}

@mixin btnNormalHover {
  color: $color_store_theme;
  background: transparent;
}

@mixin contentLayout {
  padding: 20px 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.2);
}
