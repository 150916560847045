
.pagehead {
    position:relative;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding:40px 30px;
    border-radius:20px;
    overflow:hidden;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,0.15);
  
    &__deco1 {
        position:absolute;
        top:0px; right:80px;
        transform:rotate(45deg);
        width:50px; height:300px;
        background:$color_theme;
        border-radius:30px;
        opacity:0;
        animation:deco_1 500ms 500ms forwards;
    }
    &__deco2 {
        position:absolute;
        top:10px; right:140px;
        transform:rotate(45deg);
        width:50px; height:300px;
        background:#f1f3fa;
        border-radius:30px;
        opacity:0;
        animation:deco_2 500ms 550ms forwards;
    }

    &__deco3 {
        display:none;
    }

    &__deco4 {
        display:none;
    }

    &__title {
        font-size:6.5vw;
        font-weight:700;
        color:$color_theme;
    }

    &__highlight {
        margin-top:20px;
        font-size:4vw;
        color:$color_theme;
    }

    &__user {
        margin-left:20px;
        font-weight:700;
    }

    &__infoblock {
        position:relative;
        z-index:1;
        width:100%;
        border-radius:10px;
    }

    &__searchblock {
        
        display:flex;
        margin-top:20px;

    }

}


@keyframes deco_1 {

    from {
        top:300px;
        right:350px;
    }
    to {
        top:0;
        right:50px;
        opacity:1;
    }

}

@keyframes deco_2 {

    from {
        top:300px;
        right:350px;
    }
    to {
        top:10px;
        right:110px;
        opacity:1;
    }
}




@media screen and (min-width:768px) {
        
    .pagehead {
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        &__title {
            font-size:3.5vw;

        }

        &__highlight {
            margin-top:20px;
            font-size:2.5vw;
        }

        &__user {
            margin-left:20px;
            font-weight:700;
        }

        &__infoblock {
            width:100%; 
        }

        &__searchblock {
        
            display:flex;
            margin-top:20px;

        }

    }


    @keyframes deco_1 {

        from {
            top:300px;
            right:350px;
        }
        to {
            top:0;
            right:80px;
            opacity:1;
        }

    }

    @keyframes deco_2 {

        from {
            top:300px;
            right:350px;
        }
        to {
            top:10px;
            right:140px;
            opacity:1;
        }
    }

    
    @keyframes deco_3 {

        from {
            top:300px;
            right:350px;
        }
        to {
            top:0;
            right:80px;
            opacity:1;
        }

    }

    @keyframes deco_4 {

        from {
            top:300px;
            right:350px;
        }
        to {
            top:10px;
            right:140px;
            opacity:1;
        }
    }
 
}

@media screen and (min-width:1024px) {
        
    .pagehead {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        
        &__title {
            font-size:30px;

        }

        &__highlight {
            margin-top:20px;
            font-size:17px;
        }

        &__user {
            margin-left:20px;
            font-weight:700;
        }

        &__infoblock {
            order:0;
            width:auto;
        }

        &__searchblock {
            order:0;
            display:flex;
            margin-top:0;
        }

    }
    
        
    @keyframes deco_1 {

        from {
            top:300px;
            right:350px;
        }
        to {
            top:0;
            right:80px;
            opacity:1;
        }

    }

    @keyframes deco_2 {

        from {
            top:300px;
            right:350px;
        }
        to {
            top:10px;
            right:140px;
            opacity:1;
        }

    }
    
}

@media screen and (min-width:1680px) {

    .pagehead {
        
        &__title {
            font-size:35px;

        }

        &__highlight {
            margin-top:20px;
            font-size:20px;
        }

        &__deco3 {
            display:block;
            position:absolute;
            top:-60px; right:180px;
            transform:rotate(45deg);
            width:50px; height:200px;
            border-radius:30px;
            background:$color_theme;
            background:#f1f3fa;
            opacity:0;
            animation:deco_3 500ms 550ms forwards;
        }

        &__deco4 {
            display:block;
            position:absolute;
            top:-60px; right:240px;
            transform:rotate(45deg);
            width:50px; height:200px;
            background:#f1f3fa;
            background:$color_theme;
            border-radius:30px;
            opacity:0;
            animation:deco_4 500ms 550ms forwards;
        }

    }

    @keyframes deco_3 {

        from {
            top:-300px;
            right:-150px;
        }
        to {
            top:-60px; 
            right:180px;
            opacity:1;
        }

    }

    @keyframes deco_4 {

        from {
            top:-300px;
            right:-150px;
        }
        to {
            top:-60px; 
            right:240px;
            opacity:1;
        }

    }

}


@keyframes shadow_on {

    from {
        box-shadow:0;
    }
    to {
        box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,0.15);
    }

}




