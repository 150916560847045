@import "styles/mixin.scss";

$color_auth_theme: #3cc6ec;
$color_auth_grey1: #f6f7f8;
$color_auth_lightblue1: #d6e3e8;
$color_auth_text: #49585e;
$color_auth_text_lighter: #808c91;

.auth {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0;
  width: 100%;
  min-height: 100vh;
  font-family: "Noto Sans KR", sans-serif;

  &__contact {
    position: fixed;
    bottom: 10px;
    right: 10px;
    max-width: 300px;
    font-size: 16px;
    color: $color_auth_text;
    line-height: 1.2;
  }

  &.full_height {
    height: 100vh;
  }

  &.extrainfo {
    display: none;
    &.on {
      display: flex;
    }
  }

  &__notice {
    margin-top: 10px;
    font-size: 15px;
    color: $color_auth_text_lighter;
  }

  &__formwrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    transform: translateX(-100%);
    opacity: 0;
    animation: formwrap 0.5s 0.3s forwards;
  }

  &__form {
    position: relative;
    z-index: 10;
    padding: 0px 30px;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    background: #ffffff;
  }

  &__snsBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: -0.5rem;

    &.snsItem {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: row;
    }
  }

  &__title {
    font-size: 35px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    color: $color_auth_text;
  }

  &__social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
  }

  &__text {
    margin-top: 30px;
    font-weight: 700;
    color: $color_auth_text;
  }

  &__inputwrap {
    margin-top: 30px;
  }

  &__inputblock {
    margin-top: 15px;
    &.flex {
      display: flex;
    }
    &.flexwrap {
      display: flex;
      flex-wrap: wrap;
    }
    &.no_margin {
      margin: 0;
    }
  }

  &__input {
    padding: 10px;
    width: 100%;
    font-size: 17px;
    font-weight: 700;
    line-height: 1;
    background: $color_auth_grey1;
    border: 1px solid $color_auth_lightblue1;
    border-radius: 5px;
    outline-color: $color_auth_theme;
    &::placeholder {
      color: $color_auth_text_lighter;
      font-family: "Noto Sans KR", sans-serif;
    }
    &:-ms-input-placeholder {
      color: $color_auth_text_lighter;
      font-family: "Noto Sans KR", sans-serif;
    }
  }

  &__textarea {
    padding: 10px;
    width: 100%;
    min-height: 150px;
    font-size: 17px;
    font-weight: 700;
    line-height: 1;
    background: $color_auth_grey1;
    border: 1px solid $color_auth_lightblue1;
    border-radius: 5px;
    outline-color: $color_auth_theme;
    &::placeholder {
      color: $color_auth_text_lighter;
      font-family: "Noto Sans KR", sans-serif;
    }
    &:-ms-input-placeholder {
      color: $color_auth_text_lighter;
      font-family: "Noto Sans KR", sans-serif;
    }
  }

  &__inputbtn {
    width: 48%;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    background: $color_auth_theme;
    border: 2px solid $color_auth_theme;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    &.transparent {
      color: $color_auth_theme;
      background: transparent;
    }
    &.ml {
      margin-left: 10px;
    }
    &.mr {
      margin-right: 10px;
    }
  }

  &__category {
    display: inline-block;
    margin: 0 5px;
    padding: 5px 10px;
    font-size: 17px;
    color: $color_auth_theme;
    background: white;
    cursor: pointer;
    border: 2px solid $color_auth_theme;
    transition: background 300ms;
  }

  &__categorychk {
    display: none;
    &:checked ~ label {
      color: #ffffff;
      background: $color_auth_theme;
    }
  }

  &__btnwrap {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    color: #ffffff;
    &.register {
      justify-content: center;
    }
    &.center {
      justify-content: center;
    }

    &--add {
      justify-content: center;
      margin-top: 15px;

      .auth__btn {
        width: 100%;
      }
    }

    &.modal {
      flex-direction: column;
      .auth__btn {
        width: 100%;
        margin-top: 1rem;
      }

      & + .auth__btn--close {
        width: 100%;
      }
    }
  }

  &__btn {
    padding: 10px;
    width: 48%;
    font-size: 18px;
    font-weight: 700;
    font-family: "Noto Sans KR", sans-serif;
    line-height: 1;
    color: #ffffff;
    background: $color_auth_theme;
    border: 2px solid $color_auth_theme;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    &.no_margin {
      margin: 0;
    }
    &.transparent {
      color: $color_auth_theme;
      background: transparent;
    }

    .modal + &--close {
      margin-top: 1rem;
    }
  }

  &__findinfo {
    margin-top: 30px;
  }

  &__findpw {
    padding-bottom: 5px;
    color: $color_auth_theme !important;
    background: none;
    border: none;
    border-bottom: 2px solid $color_auth_theme;
    cursor: pointer;
  }

  &__policy {
    padding: 0 10px;
    height: 250px;
    background: $color_auth_grey1;
    border: 1px solid $color_auth_lightblue1;
    overflow-y: auto;
  }

  &__policytitle {
    display: block;
    margin: 20px 0;
    font-size: 20px;
  }

  &__policydetail {
    line-height: 1.3;
  }

  &__policychkwrap {
    margin-top: 15px;
    &.flexcolumn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__policychk {
    margin-top: 10px;
  }

  &__busanunni {
    position: absolute;
    z-index: 20;
    bottom: 0px;
    right: -50px;
  }
}

@media screen and (min-width: 678px) {
  .auth {
    display: flex;
    flex-direction: row;
    width: 100%;

    &__contact {
      bottom: 20px;
      right: 20px;
    }

    &__formwrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65%;
    }

    &__form {
      position: relative;
      padding: 100px 70px;
      box-shadow: 10px 10px 23px -3px rgba(60, 198, 236, 0.75);
    }

    &__title {
      font-size: 40px;
    }

    &__social {
      margin-top: 30px;
    }

    &__text {
      margin-top: 30px;
    }

    &__inputwrap {
      margin-top: 30px;
    }

    &__inputblock {
      margin-top: 15px;
      &.no_margin {
        margin: 0;
      }
    }

    &__input {
      font-size: 17px;
    }

    &__btn {
      padding: 15px;
      font-size: 20px;
      cursor: pointer;
    }

    &__busanunni {
      position: absolute;
      z-index: 20;
      bottom: 0px;
      right: -50px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .auth {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;

    &__formwrap {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 600px;
    }

    &__form {
      position: relative;
      padding: 110px 100px;
      &.register {
        padding: 120px 80px;
      }
    }

    &__title {
      font-size: 40px;
    }

    &__social {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 30px;
    }

    &__text {
      margin-top: 30px;
      font-size: 17px;
    }

    &__inputwrap {
      margin-top: 30px;
    }

    &__inputblock {
      margin-top: 15px;
    }

    &__input {
      width: 100%;
      font-size: 17px;
    }

    &__btnwrap {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      &--add {
        margin-top: 15px;
      }
    }

    &__btn {
      padding: 15px;
      width: 48%;
      font-size: 20px;
    }

    &__busanunni {
      position: absolute;
      z-index: 20;
      bottom: 0px;
      right: -50px;
    }
  }
}

@media screen and (min-width: 1680px) {
}

@keyframes formwrap {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
}
