
.layout {

    .sort.mt {
        margin-top:15px;
    }

    .sort {
        color:#858796;

        &__title {
            text-align: right;
        }

        .category {

            &__wrap {
                display:inline-block;
                position:relative;
                font-size:16px;
                text-align:center;
            }

            &__selected {
                display:flex;
                justify-content: center;
                align-items:center;
                width:170px; height:40px;
                cursor:pointer;
                color:white;
                background: $color_theme;
                box-shadow: 10px 10px 35px 0 rgba(100, 101, 110, 0.3);
            }

            &__selected.off {
                background:rgb(187, 187, 187);
            }

            &__listwrap {
                display:none;
                position:absolute; z-index:10;
                top:200%; left:0;
                width:100%;
                box-shadow: 10px 10px 23px -5px rgba(58, 59, 69,0.35);
                &.on {
                    display:block;
                    animation:categorywrap 500ms forwards;
                }
            }

            &__list {
                padding:13px;
                background:#ffffff;
                cursor:pointer;
                &:hover {
                    background:$color_theme;
                    color:white;
                }
            }

        }

    }

}

@media screen and (min-width:768px) {
    

}

@media screen and (min-width:1024px) {

    .layout {
            
        .sort {
            color:#858796;
            &__title {
                text-align: left;
            }

          

        }

        .category {



        }
    }

}

@media screen and (min-width:1680px) {


}




@keyframes categorywrap {
    from {
        opacity:0;
    }
    to {
        opacity:1; 
        top:100%;
    }
}