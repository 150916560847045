

.ani_down_top {
    animation:aniDownTop 700ms forwards;
}

@keyframes aniDownTop {
    from {
        opacity:0;
        transform:translateY(100px);
    }
    to {
        opacity:1;
        transform:translateY(0px);
    }
}

