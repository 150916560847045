.layout.overhidden {
  overflow: hidden;
}

.layout {
  position: relative;
  padding: 20px;
  min-height: 100vh;
  background: #ffffff;
  box-shadow: 5px 10px 30px 0 rgba(100, 101, 110, 0.3);

  &__statewrap {
    margin-top: 40px;
  }

  &__searchwrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 50px;
    &.flexend {
      justify-content: flex-end;
    }
    &.mt {
      margin-top: 30px;
    }
  }

  &__content {
    margin-top: 50px;
    &.no_margin {
      margin-top: 0;
    }
  }

  &__foot {
    margin-top: 50px;
  }

  &__footbtn {
    padding: 7px 20px;
    color: #ffffff;
    background: $color_theme;
    border: 2px solid $color_theme;
    cursor: pointer;
    outline: none;
    transition: background 300ms;
    &:hover {
      color: $color_theme;
      background: white;
    }
  }
}

.layout {
  .sortwrap {
    order: 2;
    display: flex;
    margin-top: 20px;
  }
  .sort {
    order: 2;
    margin: 20px 0;
    text-align: right;
  }
}

.layout {
  .content {
    &__section {
      margin-top: 30px;
      &.no_margin {
        margin-top: 0;
      }
    }

    &__header {
      margin-bottom: 40px;
      padding-right: 2rem;
      display: flex;
      justify-content: space-between;

      .select {
        margin-top: 1rem;
        padding: 0.5rem;
      }

      &.sales {
        display: flex;
        flex-direction: column;

        .sales {
          &__select {
            margin-top: 1rem;
          }
        }
      }
    }

    &__title {
      font-size: 20px;
      font-weight: 700;
      color: $color_theme;
    }

    &__foot {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      &.flexend {
        justify-content: flex-end;
      }
    }
  }
}

.layout {
  .nodata {
    margin-top: 30px;
    padding: 50px 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    animation: nodata 1000ms forwards;
    &__text {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      color: $color_theme;
    }
  }
}

@media screen and (min-width: 768px) {
  .layout {
    &__searchwrap {
      flex-direction: row;
      align-items: flex-start;
      margin-top: 50px;
    }

    .sortwrap {
      order: 0;
      margin-top: 0px;
    }
    .sort {
      order: 0;
      margin: 0;
      text-align: left;
    }
  }
}

@media screen and (min-width: 1024px) {
  .layout {
    margin: 20px 10px 20px 300px;
    padding: 30px;
    min-height: 100vh;
    border-radius: 20px;
    &__searchwrap {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 50px;
    }
    &__content {
      margin-top: 50px;
    }
  }

  .layout {
    .nodata {
      padding: 80px 30px;
      &__text {
        font-size: 25px;
      }
    }
  }
}

@media screen and (min-width: 1680px) {
  .layout {
    margin: 20px 20px 20px 330px;
    padding: 30px;
    min-height: 100vh;
    border-radius: 20px;
    &__content {
      margin-top: 50px;
    }
  }
  .layout {
    .content {
      &__title {
        font-size: 25px;
      }
    }
  }
}

@keyframes nodata {
  from {
    transform: translateY(200px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
