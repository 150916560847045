.error {
  &__wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 70px 20px;
    width: 90%;
    text-align: center;
    box-shadow: 10px 10px 23px -3px rgba(60, 198, 236, 0.75);
    opacity: 0;
    animation: errorwrap 0.5s 0.3s forwards;
  }

  &__title {
    font-size: 30px;
  }

  &__textwrap {
    margin-top: 50px;
  }

  &__text {
    font-size: 16px;
    line-height: 1.5;
  }

  &__btnwrap {
    margin-top: 50px;
    color: #ffffff;
  }

  &__notice {
    margin-top: 2rem;
    .error__text {
      margin-top: 0.5rem;
      font-size: 1rem;
    }
  }

  &__btn {
    display: inline-block;
    margin-top: 30px;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: 700;
    font-family: "Noto Sans KR", sans-serif;
    line-height: 1;
    color: white;
    background: #3cc6ec;
    border: 2px solid #3cc6ec;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
  }
}

@media screen and (min-width: 768px) {
  .error {
    &__wrap {
      padding: 120px 100px;
      width: 80%;
    }

    &__text {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .error {
    &__wrap {
      padding: 120px 100px;
      width: auto;
    }

    &__text {
      font-size: 17px;
    }
  }
}

@keyframes errorwrap {
  from {
    opacity: 0;
    transform: translate(-50%, -150%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
