
.influencer {

    .infocardwrap {
        
    }

    .infocard {
        &.new {
            box-shadow: 0 0.15rem 1rem 0 rgba(12, 180, 226, 0.5);
        }
        &.approved {
            box-shadow: 0 0.15rem 1rem 0 rgba(12, 180, 226, 0.5);
        }

        &__new {
            position:absolute;
            top:10px; left:10px;
            font-size:14px;
            font-weight:900;
        }
        &__approved {
            position:absolute;
            top:10px; left:10px;
            font-size:14px;
            font-weight:900;
        }
        &__suspend {
            position:absolute;
            top:10px; left:10px;
            font-size:14px;
            font-weight:900;
            color:grey;
        }

    }

}

@media screen and (min-width:768px) {
    

}

@media screen and (min-width:1024px) {
    
    
}

@media screen and (min-width:1680px) {
    
    
}


