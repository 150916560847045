

    /* ::::: Firebox ::::: */
    .cus_scroll {
        scrollbar-color: white transparent; /* thumb and track color */
        scrollbar-width: thin;
    }

    /* ::::: IE 11 ::::: */
    .cus_scroll {
        scrollbar-base-color: #ffffff;
        scrollbar-face-color: #ffffff;
        scrollbar-arrow-color: #ffffff;
    }

    /* ::::: Chrome ::::: */
    .cus_scroll::-webkit-scrollbar {
        width: 12px;
    }

    .cus_scroll::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #ffffff; 
        border-radius: 10px;
    } 

    .cus_scroll::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 5px #ffffff; 
        border-radius: 10px;
        background:#ffffff;
    } 

