

.store {

    .photoinfocard {
        
        &.selected {
            box-shadow: 0 0.15rem 1rem 0 rgba(12, 180, 226, 0.6);
            transition:box-shadow 300ms;
            .photoinfocard__imgwrap {
                border:5px solid rgba(12, 180, 226, 0.3);
                transition:border 300ms;
            }
        }

        &__btn { 
            &.sold {
                color:$color_store_theme;
                background:transparent;
                border:1px solid $color_store_theme;
            }
        }
    }

    .btn__purchase {
        padding:10px 20px;
        @include btnNormal;
        &:hover {
          @include btnNormalHover;
        }
    }
    

}


@media screen and (min-width:768px) {


}


@media screen and (min-width:1024px) {


}