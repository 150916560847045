$color_sidebar_text: #809fb8;

.sidebar {
  display: none;
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1024px) {
  .sidebar {
    position: fixed;
    z-index: 20;
    top: 50%;
    left: 10px;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 10px 20px 10px;
    width: 270px;
    height: 95vh;
    color: #ffffff;
    background: $color_theme;
    border-radius: 20px;
    box-shadow: 10px 10px 35px 0 rgba(100, 101, 110, 0.3);

    &__titlewrap {
      position: relative;
      height: 35px;
      overflow: hidden;
    }

    &__title {
      font-size: 30px;
      font-weight: 900;
      text-align: center;
      transition: transform 300ms;
    }

    &__logout {
      cursor: pointer;
    }

    &__linkwrap {
      margin-top: 50px;
      padding: 0 10px;
      max-height: 50vh;
      overflow: auto;
    }

    &__link {
      display: block;
      padding: 20px 20px;
      font-size: 17px;
      border-radius: 10px;
      transition: color 300ms;
      color: #ffffff;
      &:hover {
        color: $color_theme;
        background: #ffffff;
      }
      &.on {
        color: $color_theme;
        background: #ffffff;
      }
    }

    &__intro {
      padding: 15px 15px;

      border-radius: 10px;
    }

    &__intromain {
      font-size: 20px;
      font-weight: 900;
      line-height: 1.2;
    }
    &__introsub {
      margin-top: 15px;
      font-size: 15px;
      line-height: 1.3;
      div {
        margin: 3px 0;
      }
      div:nth-last-of-type(1) {
        margin: 3px 0 0 0;
      }
    }
  }
}

@media screen and (min-width: 1680px) {
  .sidebar {
    top: 50%;
    left: 10px;
    padding: 50px 10px 20px 10px;
    width: 300px;
    &__title {
      font-size: 30px;
      font-weight: 900;
      text-align: center;
    }
    &__link {
      font-size: 18px;
    }
    &__intromain {
      font-size: 22px;
    }
    &__introsub {
      font-size: 17px;
    }
  }
}
