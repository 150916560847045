/* ::::::::::::::::::::::::::::::::::::

    Common Assets, Variables Imports
    
:::::::::::::::::::::::::::::::::::: */

@import '/src/styles/fonts.scss';
@import '/src/styles/variables.scss';
@import '/src/styles/mixin.scss';




/* :::::::::::::::::::::::::::::::::::: 

    Reset
    
::::::::::::::::::::::::::::::::::::*/

* {
    box-sizing: border-box;
}

a, a:link, a:visited {
    color:inherit;
    text-decoration: none;
}

h1,h2,h3 {
    margin:0;
}

ul {
    padding:0;
    list-style-type: none;
}

p {
    margin:0;
}





/* :::::::::::::::::::::::::::::::::::: 

    Commmon Elements
    
::::::::::::::::::::::::::::::::::::*/


body {
    font-family: 'Noto Sans Kr'; 
}

#influencer_market {
    margin:0 auto;
    max-width:1900px;
    background:$color_white;
}

.cursor {
    cursor:pointer;
}

.mb_block {
    display:block;
}


@media screen and (min-width:768px) {

    .mb_block {
        display:inline-block;
    }

}

@media screen and (min-width:1024px) {
    
   

}





/* :::::::::::::::::::::::::::::::::::: 

    Common Components
    
::::::::::::::::::::::::::::::::::::*/

@import '/src/styles/sidebar/sidebar.scss';
@import '/src/styles/common/_sort.scss';
@import '/src/styles/common/_search.scss';
@import '/src/styles/common/layout.scss';
@import '/src/styles/common/mbmenu.scss';
@import '/src/styles/common/pagehead.scss';
@import '/src/styles/common/pagination.scss';
@import '/src/styles/common/scroll/customscroll.scss';
@import '/src/styles/common/error/errorPage.scss';
@import '/src/styles/common/exception/exception.scss';
@import '/src/styles/modal/infomodal.scss';
@import '/src/styles/modal/editmodal.scss';
@import '/src/styles/modal/refusemodal.scss';
@import '/src/styles/card/infocard.scss';
@import '/src/styles/library/daumpost.scss';
@import '/src/styles/common/animation.scss';




/* :::::::::::::::::::::::::::::::::::: 

    Icons

:::::::::::::::::::::::::::::::::::: */

.svg_container {
    
    /* Login, Register pages */
    &.oauth {
        display:flex;
        .svg_wrap {
            margin:0 10px;
            width:35px;
            cursor:pointer;
        }
        path {
            fill:#49585E;
        }
    }

    svg {
        width:100%;
        height:100%;
    }

}


@media screen and (min-width:768px) {
    
}


@media screen and (min-width:1024px) {

}

@media screen and (min-width:1680px) {

    
}





/* :::::::::::::::::::::::::::::::::::: 

    Content Edit
    
::::::::::::::::::::::::::::::::::::*/


.contentedit__text {
    outline:none;
    line-height:1.3;
    &.state_edit {
       padding:5px;
       width:100%;
       border:none;
       border-bottom:1px solid grey;
       background:transparent;
    }
    &.mt10 {
        margin-top:10px;
    }
    &.pd10 {
        padding:10px;
    }
    &.textarea {
        min-height:100px;
    }
}




/* :::::::::::::::::::::::::::::::::::: 

    Bg Filter
    
::::::::::::::::::::::::::::::::::::*/

.bgfilter {
    display:none;
    position:fixed; z-index:35;
    top:0; right:0; bottom:0; left:0;
    background:rgba(0,0,0,0.3);
    &.on {
        display:block;
    }
}







