
$boder_radius:10px;
$color_modal_refuse:#506D84;

@import './infomodalRevenue';

.infomodal {
    display:none;
    position:fixed; z-index:40;
    top:50%; left:50%;
    transform:translate(-50%, -50%);
    width:90%;
    padding:30px;
    text-align: center;
    color:#656565;
    background:white;
    border-radius:$boder_radius;
    overflow:hidden;
    transform:scale(0);
    &.on {
        display:block;
        animation:modalVisible 0.3s forwards;
    }

    &__titlewrap {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }

    &__logo {
        display:flex;
        justify-content: center;
        align-items: center;
        width:80px; height:80px;
        border-radius: 100%;
        box-shadow: 10px 10px 35px 0 rgba(100, 101, 110, 0.2);
        svg {
            width:30px;
            height:30px;
            fill:$color_theme;
        }
        path {
            fill:$color_theme;
        }
    }

    &__logo.black {
        svg {
            fill:$color_black;
        }
        path {
            fill:$color_black;
        }
    }

    &__logo.info {
        svg {
          width:38px;
          height:38px;
        }
    }

    &__logo.detail {
        svg {
            width:40px;
            height:40px;
        }
        path {
            stroke:$color_theme;
            stroke-width:0.2px;
        }
    }

    &__logo.refuse {
        svg {
            width:40px; 
            height:40px;
        }
    }

    &__title {
        margin-top:30px;
        font-size: 25px;
        font-weight:700;
    }

    &__subtitle {
        margin-top:10px;
        font-size:15px;
    }

    &__block {
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin:20px 0 0 0;
        &:nth-of-type(1) {
            margin:10px 0 0 0;
        }
        &.highlight {
            font-size:17px;
            font-weight:700;
        }
        &.center {
            text-align: center;
        }
    }

    &__borderblock {
        display:inline-block;
        padding:3px 15px 3px 15px;
        font-weight:700;
        color:$color_theme;
        border:3px solid $color_theme;
        border-bottom:none;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    &__linkwrap {
        margin-top:15px;
        text-align: center;
        color:#ffffff;
    }

    &__link {
        display:inline-block;
    }

    &__naverlink {
        display:inline-block;
        padding:5px 15px;
        font-size:16px;
        .svg_naver {
            display:block;
            width:22px;
            height:22px;
        }
    }

    a.infomodal__naverlink {
        color:#ffffff;
    }
    

    &__content {
        padding:20px 0;
        max-height:200px;
        font-size:16px;
        line-height:1.5;
        overflow:auto;
    }

    &__inputwrap {
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top:10px;
    }

    &__label {
        margin-right:10px;
    }

    &__input {
        padding:10px;
        font-size:15px;
        width:100%;
        outline:none;
        border-radius:5px;
        border:1px solid $color_black;
        &::placeholder {
            color:$color_black;
            opacity:0.7;
        }
        &:-ms-input-placeholder {
            color:$color_black;
            opacity:0.7;
        }
    }

    &__btnwrap {
        display:flex;
        margin-top:30px;
    }

    &__btn {
        flex:1 1;
        padding:15px 0;
        font-size:17px;
        font-weight:700;
        text-align: center;
        color:#ffffff;
        background:$color_theme;
        border:none;
        border:1px solid $color_theme;
        border-radius:5px;
        cursor:pointer;
        outline:none;
        transition:background 300ms;
    }

}


/* ::::::::::: Infomodal - Refuse ::::::::::: */

.infomodal.refuse {

    .infomodal__title {
        color:$color_black;
    }

    .infomodal__btn { 
        border:1px solid $color_black;
        background:$color_black;
    }

}

/* ::::::::::: Infomodal - hourlist ::::::::::: */

.infomodal.hourlist {

    .infomodal__hourlistwrap {
        margin-top:20px;
        padding:10px;
        max-height:200px; 
        overflow-y:auto;
    }

    .infomodal__hourlist  {
        margin-top:10px;
        padding:10px;
        font-size:17px;
        font-weight:bold;
        cursor:pointer;
        border:2px solid #656565;
        transition:background 300ms;
        &:nth-of-type(1) {
            margin-top:0;
        }
        &:hover {
            color:#ffffff;
            background:$color_theme; 
            border:2px solid #ffffff;
        }
    }

}



@media screen and (min-width:768px) {
        
    .infomodal {
        padding:40px;
        width:70%;

        &__title {
            font-size:30px;
        }

        &__highlight {
            font-size:20px;
        }

        &__label {
            font-size:25px;
        }
    
        &__input {
            font-size:20px;
        }

        &__content {
            font-size:20px;
        }

        &__btn {
            padding:15px 0;
            font-size:25px;
        }

    }

}

@media screen and (min-width:768px) {
        
    .infomodal {
        width:70%;
        
        .infomodal.hourlist {

            .infomodal__hourlistwrap {
                max-height:250px; 
            }

        }

    }
    
}


@media screen and (min-width:1024px) {
        
    .infomodal {
        display:none;
        position:fixed; z-index:40;
        top:50%; left:50%;
        transform:translate(-50%, -50%);
        width:500px;
        padding:50px;

        &__title {
            font-size: 30px;
        }
        
        &__subtitle {
            margin-top:15px;
            font-size:15px;
        }

        &__logo.clock {
            svg {
                width:45px; 
                height:45px;
            }
        }

        &__highlight {
            font-size:20px;
        }

        &__block {
            font-size:17px;
        }

        &__inputwrap {
            display:flex;
            justify-content: center;
            align-items: center;
        }

        &__label {
            margin-right:10px;
        }

        &__input {
            padding:10px;
            font-size:15px;
            width:70%;
        }
        

        &__btnwrap {
            display:flex;
        }

        &__btn {
            flex:1 1;
            padding:15px 0;
            font-size:20px;
            &:hover {
                color:$color_theme;
                background:#ffffff;
            }
        }

    }

    /* ::::::::::: Infomodal - Refuse ::::::::::: */

    .infomodal.refuse {

        .infomodal__title {
            color:$color_modal_refuse;
        }

        .infomodal__btn { 
            border:1px solid $color_modal_refuse;
            background:$color_modal_refuse;
            &:hover {
                color:$color_modal_refuse;
                background:#ffffff;
            }
        }
        
    }

    .infomodal__title {
        color:$color_black;
    }



}




@media screen and (min-width:1680px) {
    
}


@keyframes modalVisible {

    from {
        transform:scale(0) translate(-50%, -50%);
        transform-origin:0% 0%;
    }
    to {
        transform:scale(1) translate(-50%, -50%);
        transform-origin:0% 0%;
    }
    
}

