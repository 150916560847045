
.admin {
 
    .photoinfocard {
        &.selected {
            .photoinfocard__imgwrap {
                border: 5px solid #0cb4e2;
            }
        }
        &.closed {
            opacity:0.5;
            .photoinfocard__head {
                color:grey;
            }
        }
    }

}


@media screen and (min-width:768px) {

}

@media screen and (min-width:1024px) {
        
    .admin {
        .photoinfocardwrap {
            margin: 0 10px;
        }
    }

}

@media screen and (min-width:1680px) {
    
}

