$color_text_revenue:$color_grey3;


/* ::::::::::: Infomodal - Revenue ::::::::::: */


@media screen and (min-width:768px) {
        
    .infomodal.hourlist {
        &__revenue {
            padding:10px;
            max-height:35vh; 
            border-radius:10px;
            overflow-y:auto;
        }
    }
        
    .infomodal {

        .revenuemodal {

            &__container {
                padding:10px;
                width:100%;
                max-height:35vh; 
                border-radius:10px;
                overflow-y:auto;
            }

            &__infowrap {
                width:90%;
                margin-top:15px;
                padding:20px;
                border-radius:10px;
                box-shadow: 0 0.15rem 0.75rem 0 rgba(58, 59, 69, 0.2);
                &:nth-of-type(1) {
                    margin-top:0;
                }
            }

            &__titleblock {
                display:flex;
                align-items: center;
                font-size:17px;
                font-weight:bold; 
                text-align:left;
            }
            
            &__titleicon {
                margin:0 10px 0 0px;
                svg {
                    display:block;
                    width:20px;
                    height:20px;
                    fill:$color_text_revenue;
                }
            }

            &__title {
                font-size:17px;
                font-weight:700;
                color:$color_text_revenue;
            }

            &__infoblock {
                display:flex;
                justify-content: space-between;
                align-items: flex-start;
                margin:10px 0 0 0;
                font-size:15px;
                color:$color_text_revenue;
                &.flexend {
                    justify-content: flex-end;
                }
            }

        }

    }

}


@media screen and (min-width:1024px) {

    
    .infomodal {

        .revenuemodal {

            &__container {
                padding:10px 20px;
                max-height:35vh; 
            }

            &__infowrap {
    
            }

            &__titleblock {
                font-size:20px;
            }
                
            &__infoblock {
                font-size:17px;
            }
            
        }
    
    }

}