
$color_theme:#0cb4e2;
$color_theme_lighter:#0fc1f1;
$color_theme_darker:#0fa6cf;

$color_white:white;
$color_black:black;

$color_light_grey1:#f6f6f6;
$color_light_grey2:#eeeeee;
$color_light_grey3:#d4d4d4;
$color_light_grey4:#bebebe;

$color_grey:grey;
$color_grey1:#adadad;
$color_grey2:#929292;
$color_grey3:#6d6d6d;



