.photoinfocardwrap {
  display: flex;
  flex-wrap: wrap;
}

.photoinfocard {
  position: relative;
  margin: 10px 0;
  padding: 50px 10px;
  width: 100%;
  text-align: center;
  color: $color_grey2;
  border-radius: 10px;
  box-shadow: 0 0.15rem 1rem 0 rgba(100, 101, 110, 0.2);

  &__imgwrap {
    margin: 0 auto;
    padding: 3px;
    width: 200px;
    height: 200px;
    border-radius: 100%;
    border: 5px solid #f1f1f1;
  }

  &__img {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    @include bgImg;
  }

  &__new {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
    font-weight: 900;
    color: $color_theme;
  }

  &__approved {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
    font-weight: 900;
    color: $color_theme;
  }

  &__suspend {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 14px;
    font-weight: 900;
    color: grey;
  }

  &__name {
    width: 80%;
    margin: 0 auto 25px auto;
    font-size: 23px;
    font-weight: 700;
    border-radius: 20px;
    white-space: nowrap;
  }

  &__block {
    margin-top: 20px;
    padding: 0 20px;
    &.no_margin {
      margin-top: 0;
    }
  }

  &__sales {
    margin-top: 5px;
  }

  &__head {
    font-size: 17px;
    color: $color_theme;
  }

  &__content {
    margin-top: 10px;
    font-size: 15px;
    line-height: 1.3;
    word-break: keep-all;
    .svg_instagram {
      width: 24px;
      height: 24px;

      path {
        fill: $color_grey2;
        stroke: $color_grey2;
        stroke-width: 0.3px;
      }
    }
  }

  &__intro {
    height: 50px;
    overflow: hidden;
  }

  &__btnwrap {
    margin-top: 25px;
    &.second {
      margin-top: 10px;
    }
  }

  &__btn {
    display: inline-block;
    margin: 5px;
    padding: 7px 10px;
    min-width: 90px;
    font-size: 15px;
    color: white;
    background: $color_theme;
    border: 1px solid $color_theme;
    cursor: pointer;
    outline: none;
    transition: background 300ms;
  }

  &__btn.mb_block {
    display: block;
    margin: 10px auto;
  }
}

@media screen and (min-width: 768px) {
  .photoinfocardwrap {
    justify-content: space-between;
  }

  .photoinfocard {
    margin: 10px;
    width: 46%;
    &__new {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 14px;
    }

    &__name {
      font-size: 25px;
    }

    &__head {
      font-size: 17px;
    }

    &__content {
      margin-top: 10px;
      font-size: 15px;
    }

    &__btnwrap {
      margin-top: 25px;
    }

    &__btn {
      margin: 0 5px;
      padding: 7px 10px;
      font-size: 15px;
      &:hover {
        color: $color_theme;
        background: transparent;
      }
    }
    &__btn.mb_block {
      display: inline-block;
      margin: 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .photoinfocard {
    margin: 10px;
    padding: 5vw 0px;
    &__new {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 14px;
    }

    &__name {
      margin: 0 auto 30px auto;
      font-size: 25px;
    }

    &__head {
      font-size: 17px;
    }

    &__content {
      margin-top: 10px;
      font-size: 15px;
    }
    &__content.address {
      min-height: 40px;
    }

    &__btnwrap {
      margin-top: 25px;
    }

    &__btn {
      margin: 5px auto;
      padding: 7px 10px;
      font-size: 15px;
      &:hover {
        color: $color_theme;
        background: transparent;
      }
    }
  }
}

@media screen and (min-width: 1300px) {
  .photoinfocard {
    &__btnwrap {
      &.second {
        margin-top: 0px;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .photoinfocardwrap {
    justify-content: flex-start;
  }

  .photoinfocard {
    display: flex;
    margin: 10px 0px;
    padding: 2.5vw 30px;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    &__imgwrap {
      margin: 0;
      width: 190px;
      min-width: 190px;
      height: 190px;
    }

    &__summarywrap {
      display: flex;
      align-items: center;
      .infolist__block {
        overflow: hidden;
      }
    }

    &__summary {
      margin: 0 20px;
    }

    &__block {
      margin-top: 0px;
      padding: 0;
      max-width: 150px;
    }

    &__block.email {
      width: 200px;
      word-break: break-all;
    }

    &__content {
      min-width: 100px;
    }

    &__name {
      margin: 0;
      width: 150px;
    }

    &__btn {
    }

    &__btn.pc_block {
      display: block;
      margin: 5px auto;
    }

    &__btnwrap {
      margin-top: 0px;
    }
  }
}

@media screen and (min-width: 1680px) {
  .photoinfocard {
    &__new {
      font-size: 15px;
    }

    &__name {
      margin: 0 auto 30px auto;
      font-size: 25px;
    }

    &__head {
      font-size: 20px;
    }

    &__content {
      font-size: 17px;
    }

    &__btn {
      padding: 7px 15px;
      font-size: 17px;
    }
  }
}

@media screen and (min-width: 1800px) {
  .photoinfocard {
    margin: 10px;
  }
}

@keyframes infolist_deco {
  from {
    opacity: 0;
    transform: translate(-300px, -300px);
  }
  to {
    opacity: 0.5;
    transform: translate(0px, 0px);
  }
}
