$color_sidebar:$color_theme_lighter;

@mixin sidebar_gradient {
    background:$color_theme;
    background:linear-gradient(25deg,#0072dc 50%, #75cce6);
}

.infdetail {
    @import './InfluencerDetailRevenue.scss';
}

.infdetail {
    display:none;
    position:fixed; z-index:100;
    top:0; left:0;
    width:100%;
    height:100vh; 
    background:white;
    transform:translate(-100%, 0);
    overflow:auto;
    &.on {
        display:block;
        animation: ani_infdetail 0.3s forwards;
        .detail {
            &__imgdeco {
                animation: ani_imagedeco 0.7s ease 0.4s forwards;
            }
        }
    }

    .detail {

        &__container {
            position:relative;
            padding:10px;
        }

        &__closewrap {
            display:flex;
            justify-content: space-between;
            align-items: center;
            padding:0 10px;
            width:100%;
            height:50px;
            background:$color_sidebar;
            border-radius: 10px;
            box-shadow:0 3px 15px 0 rgba(0,0,0,0.4);
        }

        &__titlewrap {
            display:flex;
            align-items: center;
        }

        &__titleicon {
            margin:0 10px 0 0;
            svg {
                display:block;
                width:30px;
                height:30px;
            }
            path {
                fill:white;
            }
            circle {
                fill:none;
                stroke:white;
            }
        }

        &__title {
            font-size:17px;
            font-weight: 700;
            color:#ffffff;
        }

        &__close {
            display:flex;
            justify-content: center;
            align-items: center;
            width:30px;
            height:30px;
            border-radius: 100%;
            cursor:pointer;
            svg {
                width:18px;
                height:18px;
            }
            path {
                stroke:white;
                stroke-width:3px;
            }
        }

        &__head {
            position:relative;
            padding:0px 0px 0 0px;
            border-bottom:3px solid $color_white;
            // @include sidebar_gradient;
            &.flexcolumn {
                flex-direction: column;
            }
        }

        &__profile {
            margin:20px 0 0 0;
        }

        &__profileblock {
            display:flex;
            align-items: flex-end;
        }


        &__body {
  
        }

        &__nickname {
            margin:5px 0 0 0;
            padding:0px;
            font-size:17px !important;
            color:$color_grey1;
        }

        &__ghost {
            flex:0 0 20px;
        }

        &__profileblock {
            margin:20px 0 0 0;
            padding:0 5px;
        }

        &__requestwrap {
            margin-top:30px;
            text-align: center;
        }

        &__request {
            padding:6px 25px; 
            font-size:16px;
            color:$color_theme;
            background:#ffffff;
            border:2px solid #ffffff;
            cursor:pointer;
            transition:background 300ms;
        }

        &__block {
            display:flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding:15px;
            margin:30px auto 0 auto;
            border-bottom:2px solid $color_light_grey2;
            color:$color_theme;
        }

        &__blockicon {
            flex: 0 0 40px;
            display:flex;
            justify-content: center;
            align-items: center;
            margin:0 20px 0 0;
            height:40px;
            background:$color_sidebar;
            border-radius: 10px;
            svg {
                display:block;
                width:22px;
                height:22px;
            }
            path {
                fill:#ffffff;
            }
            svg.svg_check {
                path {
                    fill:none;
                    stroke:#ffffff;
                }
            }
        }

        &__blockcontent {
            flex:1 1 auto;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &__blocktitle {
            font-size:18px; 
            font-weight:700;
            color:$color_sidebar;
        }

        &__info {
            margin-top:10px;
            color:rgb(112, 112, 112);
        }

        &__social {
            svg {
                fill:black;
                width:25px; height:25px;
            }
        }

        &__profileblock {
            position:relative;
            margin:10px auto 0 auto; 
            border-radius:5px;
            color:#3b3b3b;
        }

        &__imgwrap {
            position:relative;
            display:flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        &__img {
            position:relative;
            width:170px;
            height:170px;
            border-radius:10%;
            @include bgImg;
        }

        &__imgdeco {
            position:absolute;
            top:13px; right:-13px;
            width:100%;
            height:100%;
            background:$color_sidebar;
            border-radius:10px;
            opacity:0;
            transform:translate(-13px, -13px);
        }

        &__namewrap {
            padding:0 0px 0px 40px;
        }

        &__name {
            margin:5px 0 0 0;
            font-size:23px;
            font-weight:700;
        }

        &__categorywrap {
            display:flex;
            flex-direction: row;
            justify-content: center;
            margin:20px 0 0 0;
        }

        &__category {
            display:flex;
            justify-content: center;
            align-items: center;
            margin:0 5px;
            padding:5px 10px; 
            min-width:50px;
            font-size:13px;
            color:$color_grey2;
            background:$color_light_grey2;
            border-radius:20px;
            &:nth-of-type(1) {
                margin:0;
            }
        }

        &__intro {
            font-size:16px;
            line-height:1.5; 
        }

        &__requestbtn {
            display:flex;
            justify-content: center;
            align-items: center;
            width:100%;
            height:50px;
            font-size:17px;
            color:#ffffff;
            background:$color_sidebar;
            border:none;
            border-radius: 10px;
            cursor: pointer;
            transition:box-shadow 300ms;
            &:hover {
                box-shadow:0 3px 15px 0 rgba(0,0,0,0.4);
            }
        }
        
    }


    .pwchange {
        display:flex;
        align-items: center;
        flex-wrap: nowrap;
        overflow:hidden;
        &__title {
            flex: 0 0 100%;
            cursor: pointer;
            transition:transform 300ms;
        }

        &__info {
            flex: 0 0 100%;
            transition:transform 300ms;
        }

        &__infoinner {
            display:flex;
            flex-direction: column;
            width:100%;
            transition:transform 300ms;
        }

        &__input {
            padding:7px;
            width:100%;
            outline:none;
            border:1px solid rgb(165, 165, 165);
        }

        &__btn  {
            margin:3px 0 0 0;
            padding:7px 10px; 
            font-size:14px;
            font-weight:bold;
            color:$color_theme;
            background:#ffffff;
            border:none;
            border:1px solid $color_theme;
            cursor:pointer;
            transition:background 300ms;
            &:hover {
                color:#ffffff;
                background:$color_theme;
            }
        }

    }

    .pwchange.on {
        .pwchange__title {
            transform:translateX(-100%);
        }
        .pwchange__info {
            transform:translateX(-100%);
        }
    }



 
}


@media screen and (min-width:768px) {

    .infdetail {
        width:370px;
        max-width:80%;

        .detail {
       
            &__category {

            }

            &__intro {
              
            }

            &__profileblock {
               
            }

            &__imgwrap {

            }

            &__img {
             
            }

            &__name {
          
            }
            
        }

    }
    
}


@media screen and (min-width:1024px) {

    .infdetail {
        width:370px;

        .detail {
            position:relative;

            &__close {
                width:40px;
                height:40px;
                transition:background 300ms;
                svg {
                    width:22px;
                    height:22px;
                }
                path {
                    transition:stroke 300ms;
                }
            }

            &__close:hover {
                background:#ffffff;
                path {
                    
                    stroke:$color_sidebar;
                }
            }

            &__name {
   
            }

            &__request {
                margin:0 auto;
                padding:6px 25px; 
                font-size:16px;
                background:white;
                color:$color_theme;
                border:2px solid #ffffff;
                &:hover {
                    color:#ffffff;
                    background:transparent;
                }
            }

            &__block.pc_nomargin {
                margin-top:0;
            }

            &__profileblock {
              
            }

            &__imgwrap {
   
            }

            &__img {

            }

            &__category {
     
            }

            &__intro {

            }
            
        }

    }
    
}


@media screen and (min-width:1680px) {

    .infdetail {

    }

}


@keyframes ani_infdetail {
    from {
        transform:translate(-100%, 0);
    }
    to {
        transform:translate(0, 0);
    }
}


@keyframes ani_imagedeco {
    from {
        opacity:0;
        transform:translate(-13px, -13px);
    }
    to {
        opacity:0.5;
        transform:translate(0, 0);
    }
}