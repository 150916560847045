
$boder_radius:10px;
$color_modal_refuse:#0c4472;


.refusemodal {
    display:none;
    position:fixed; z-index:40;
    top:50%; left:50%;
    transform:translate(-50%, -50%);
    width:90%;
    padding:30px;
    text-align: center;
    color:#656565;
    background:white;
    border-radius:$boder_radius;
    overflow:hidden;
    transform:scale(0);

    &.on {
        display:block;
        animation:modalVisible 0.3s forwards;
    }

    &__titlewrap {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }


    &__logo {
        display:flex;
        justify-content: center;
        align-items: center;
        width:80px; height:80px;
        border-radius: 100%;
        box-shadow: 10px 10px 35px 0 rgba(100, 101, 110, 0.2);
        svg {
            width:40px;
            height:40px;
            fill:$color_modal_refuse;
        }
        .svg_cancel_light {
            width:40px;
            height:40px;
            path {
                fill:$color_modal_refuse;
                stroke:$color_modal_refuse;
                stroke-width: 1px;
            }
        }
    }

    &__title {
        margin:30px 0 15px 0;
        font-size:7vw;
        font-weight:700;
        color:$color_modal_refuse;
    }

    &__block {
        padding:5px 10px;
        font-size:3.5vw;

    }

    &__highlightblock {
        display:inline-block;
        padding:0px 15px;
        font-size:14px;
        font-weight:700;
        color:$color_modal_refuse;
        // background:$color_modal_refuse;
        border:2px solid $color_modal_refuse;
        border-top:none;
        border-bottom:none;
    }

    &__inputwrap {
        display:flex;
        justify-content: center;
        align-items: center;
        margin-top:10px;
        &.column {
            flex-direction: column;
        }
    }

    &__label {
        font-size:5.5vw;
        font-weight:700;
    }

    &__input {
        padding:10px;
        font-size:4vw;
        width:100%;
        outline:none;
        border-radius:5px;
        border:1px solid $color_black;
        &::placeholder {
            color:$color_black;
            opacity:0.7;
        }
        &:-ms-input-placeholder {
            color:$color_black;
            opacity:0.7;
        }
    }

    &__btnwrap {
        display:flex;
        margin-top:30px;
    }

    &__btn {
        flex:1 1 auto;
        padding:12px 0;
        font-size:4.5vw;
        font-weight:700;
        text-align: center;
        color:#ffffff;
        border:2px solid $color_modal_refuse;
        background:$color_modal_refuse;
        cursor:pointer;
        outline:none;
        transition:background 300ms;
    }

}

@media screen and (min-width:768px) {

    .refusemodal {
        padding:40px;
        width:70%;

        &__title {
            font-size:35px;
        }

        &__block {
            font-size:20px;
        }

        &__label {
            font-size:25px;
        }
    
        &__input {
            font-size:20px;
        }

        &__btn {
            padding:15px 0;
            font-size:25px;
        }

    }

}


@media screen and (min-width:1024px) {
            
    .refusemodal {
        display:none;
        position:fixed; z-index:40;
        top:50%; left:50%;
        transform:translate(-50%, -50%);
        width:500px;
        padding:50px;

        &__title {
            font-size:30px;
        }

        &__block {
            font-size:17px;
        }

        &__highlightblock {
            font-size:14px;
        }
    
            
        &__inputwrap {
            display:flex;
            justify-content: center;
            align-items: center;
        }

        &__label {
           
        }

        &__input {
            padding:10px;
            font-size:15px;
            width:70%;
        }
        
        &__btnwrap {
            display:flex;
        }

        &__btn {
            flex:1 1;
            padding:15px 0;
            font-size:20px;
            &:hover {
                color:$color_modal_refuse;
                background:#ffffff;
            }
        }

    }

}


@media screen and (min-width:1680px) {
    
}

@keyframes modalVisible {

    from {
        transform:scale(0) translate(-50%, -50%);
        transform-origin:0% 0%;
    }

    to {
        transform:scale(1) translate(-50%, -50%);
        transform-origin:0% 0%;
    }
    
}
