@import 'styles/store/_variables.scss';


#store_records {

    .notice {
        display:block;
        margin-top:10px;
        font-size:16px;
        font-weight:700;
        line-height:1.3;
        color:#858796;
    }

}


@media screen and (min-width:768px) {
        
        
    #store_records {
        .notice {
            display:block;
        }

    }

    
}


@media screen and (min-width:1024px) {

    #store_records {

        .notice {
            display:none;
        }

    }


}


@media screen and (min-width:1680px) {

    #store_records {


    }

}



#store_records {


}


    
@media screen and (min-width:768px) {

        
    #store_records {


    }


}


@media screen and (min-width:1024px) {
        
    #store_records {


    }

}


@media screen and (min-width:1680px) {

    #store_records  {

        .datatable {
            
            &__headItem {
                font-size:18px;
            }

            &__bodyitem {
                font-size:17px;
            }

        }

    }

}

