
$boder_radius:10px;
$color_input_border:#d1d1d1;
$color_input_text:#656565;

.editmodal {
    display:none;
    position:fixed; z-index:40;
    top:50%; left:50%;
    transform:translate(-50%, -50%);
    width:90%;
    max-height:100vh;
    padding:30px;
    text-align: center;
    color:#656565;
    background:white;
    border-radius:$boder_radius;
    overflow:auto;
    transform:scale(0);
    &.on {
        display:block;
        animation:modalVisible 0.3s forwards;
    }

    &__titlewrap {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
    }

    &__logo {
        display:flex;
        justify-content: center;
        align-items: center;
        width:70px; height:70px;
        border-radius: 100%;
        box-shadow: 10px 10px 35px 0 rgba(100, 101, 110, 0.2);
        svg {
            width:30px;
            height:30px;
            fill:$color_theme;
        }
        path {
            fill:$color_theme;
        }
    }

    &__logo {
        .svg_plus {
            svg {
                width:35px;
                height:35px;
             }
             path  {
                 stroke:$color_theme;
             }     
        }
    }

    &__logo.info {
        svg {
            width:30px;
            height:30px;
        }
    }

    &__logo.add {
        svg {
           width:35px;
           height:35px;
        }
        path  {
            stroke:$color_theme;
            stroke-width: 0px;
        }
    }

    &__logo.exchange {
        svg {
            width:35px;
            height:35px;
        }
    }

    &__title {
        margin-top:40px;
        font-size: 25px;
        font-weight:700;
    }

    &__block {
        margin-top:15px;
        &.highlight {
            font-size:17px;
            font-weight:700;
            line-height:1.5;
        }
        &.center {
            text-align:center;
        }
    }

    &__block:nth-of-type(1) {
        margin-top:0; 
    }

    &__content {
        margin:20px 0 0 0;
        padding:0px 20px 0 20px;
        max-height:40vh;
        font-size:16px;
        line-height:1.5;
        overflow:auto;
    }

    &__inputwrap {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:35px;
        &.no_margin {
            margin-top:0;
        }
    }

    &__inputwrap:nth-of-type(1) {
        margin-top:0;
    }

    &__label {
        margin-bottom:10px;
        color:rgb(168, 168, 168);
        font-weight:bold;
    }

    &__input {
        padding:5px 5px 10px 5px;
        font-size:15px;
        width:100%;
        color:$color_input_text;
        text-align: center;
        outline:none;
        border:none;
        border-bottom:2px solid $color_input_border;
        &::placeholder {
            color:$color_grey2;
            opacity:0.7;
            text-align: center;
        }
        &:-ms-input-placeholder {
            color:$color_grey2;
            opacity:0.7;
            text-align: center;
        }
    }

    &__textarea {
        padding:10px;
        font-size:15px;
        min-height:130px;
        width:90%;
        color:$color_input_text;
        line-height:1.3;
        outline:none;
        border-radius:5px;
        border:2px solid $color_input_border;
        resize: none;
    }

    &__btnwrap {
        display:flex;
        margin-top:30px;
    }

    &__btn {
        flex:1 1;
        padding:15px 0;
        font-size:17px;
        font-weight:700;
        text-align: center;
        color:#ffffff;
        background:$color_theme;
        border:none;
        border:1px solid $color_theme;
        cursor:pointer;
        outline:none;
        transition:background 300ms;
    }

    &__feetypewrap {
        display:flex;
        flex-direction: row;
        justify-content: center;
        margin-top:20px;
    }

    &__feetype {
        margin-left:10px;
        padding:7px 10px;
        min-width:90px;
        color:#ffffff;
        background:#656565;
        cursor: pointer;
        &:nth-of-type(1) {
            margin-left:0;
        }
        &.on {
            background:$color_theme;
        }
    }

}


.editmodal.refuse {

    .editmodal__title {
        color:$color_black;
    }

    .editmodal__btn { 
        border:1px solid $color_black;
        background:$color_black;
    }

}


@media screen and (min-width:768px) {
        
    .editmodal {
        padding:40px;
        width:70%;

        &__title {
            font-size:30px;
        }

        &__block {
            &.highlight {
                font-size:20px;
            }
           
        }

        &__label {
            font-size:25px;
        }
    
        &__input {
            font-size:20px;
        }

        &__content {
            font-size:20px;
        }

        &__btn {
            padding:15px 0;
            font-size:25px;
        }
        
    }

}

@media screen and (min-width:1024px) {
        
    .editmodal {
        display:none;
        position:fixed; z-index:40;
        top:50%; left:50%;
        transform:translate(-50%, -50%);
        width:500px;
        padding:50px;
        &__logo {
            width:85px; height:85px;
            svg {
                width:35px; 
                height:35px;
            }
        }
            
        &__logo.info {
            svg {
                width:35px;
                height:35px;
            }
        }

        &__logo.add {
            svg {
                width:45px;
                height:45px;
            }
        }

        &__title {
            font-size: 30px;
        }

        &__block {
            &.highlight {
                font-size:17px;
            }
          
        }

        &__inputwrap {
            display:flex;
            justify-content: center;
            align-items: center;
        }

        &__label {
            font-size:18px;
        }

        &__input {
            font-size:16px;
            width:90%;
        }

        &__btnwrap {
            display:flex;
        }

        &__btn {
            flex:1 1;
            padding:15px 0;
            font-size:20px;
            &:hover {
                color:$color_theme;
                background:#ffffff;
            }
        }

    }

}



.editmodal.refuse {

    .editmodal__title {
        color:$color_black;
    }

    .editmodal__btn { 
        border:1px solid $color_black;
        background:$color_black;
        &:hover {
            color:$color_black;
            background:#ffffff;
        }
    }

}


@media screen and (min-width:1680px) {
    
}


@keyframes modalVisible {

    from {
        transform:scale(0) translate(-50%, -50%);
        transform-origin:0% 0%;
    }
    to {
        transform:scale(1) translate(-50%, -50%);
        transform-origin:0% 0%;
    }
    
}