
.daumpost {
    display:none;
    position:fixed; z-index:50;
    top:50%; left:50%;
    transform:translate(-50%, -50%);
    width:90%; height:80vh;
    padding:30px;
    text-align: center;
    color:#656565;
    background:white;
    border-radius:$boder_radius;
    overflow:auto;
    transform:scale(0);
    &.on {
        display:block;
        animation:modalview 0.3s forwards;
    }

    &__close {
        position:absolute;
        top:0px; right:20px;
        cursor:pointer;
        svg {
            width:20px; height:20px; 
        }
    }
}


@media screen and (min-width:768px) {
    

}


@media screen and (min-width:1024px) {

    .daumpost {
        display:none;
        position:fixed; z-index:40;
        top:50%; left:50%;
        transform:translate(-50%, -50%);
        width:500px;
        padding:50px;

    }

}


@media screen and (min-width:1680px) {

    
}





