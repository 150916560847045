
$color_super_theme:$color_theme; 
$color_super_grey1:#f1f3fa;
$color_super_grey2 :#858796;

.super {

    .setting {

        &__section {
            margin-top:30px;
            &.no_margin {
                margin-top:0;
            }
        }

        &__ctnlist {
            display:none;
            margin-bottom :30px;
            &.on {
                display:block;
            }
        }

        &__list {
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-start;
            margin-top:20px;
            padding:30px 20px;
            box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,0.15);
            border-radius:10px;
            &:nth-of-type(1) {
                margin-top:0;
            }
        }
        
        &__title {
            font-size:20px;
            font-weight:900;
            color:$color_super_theme;
            margin-bottom:30px;
        }

        &__inputwrap {
            display:flex;
            flex-direction: column;
        }

        &__inputblock {
            display:flex;
            flex-direction: row;
            align-items: center;
            margin-top:10px;
            &:nth-of-type(1) {
                margin-top:0;
            }
        }

        &__inputlabel {
            margin-right:10px;
            min-width:80px;
            color:$color_super_grey2;
        }

        &__input {
            margin-right:20px;
            padding:6px 10px;
            width:150px;
            color:$color_super_grey2;
            border-radius:5px;
            border:1px solid #b2b5be;
            outline:none;
        }

        &__valuewrap {
            display:flex;
            flex-direction: column;
            justify-content:space-around;
            width:100%;
        }

        &__valueblock {
            display:flex;
            flex-direction: column;
            align-items: center;
            margin-top:10px;
            color:$color_super_grey2;
            &:nth-of-type(1) {
                margin-top:0;
            }
        }

        &__valuelabel {
            font-weight:900;
        }

        &__value {
            margin-top:5px;
            max-width:250px;
            font-size: 18px;
            line-height:1.2;
            text-align: center;
            color:$color_super_grey2;
            transition:color 300ms;
        }

        &__inputbtn {
            margin-top:10px;
            padding:5px 20px;
            height: 100%;
            font-size: 16px;
            color: #ffffff;
            background: $color_super_theme;
            border:2px solid $color_super_theme;
            outline: none;
            cursor: pointer;
            transition:background 300ms;
            &:hover {
                color:$color_super_theme;
                background:#ffffff;
            }
        }

        &__item {

        }

    }

}

@media screen and (min-width:768px) {
    

}


@media screen and (min-width:1024px) {
    
        
    .super {
        
        .list {
            &__item {
                padding:20px 10px;
            }
            
        }
        .setting {
            &__list {
                flex-direction: row;
            }

            &__valuewrap {
                display:flex;
                flex-direction: row;
                justify-content:space-around;
                width:100%;
            }
    
            &__valueblock {
                min-width:250px;
            }
    
            &__value {
                font-size: 18px;
                text-align: center;
            }

        }

    }

}


@media screen and (min-width:1350px) {
    
    .super {

        .setting {
            &__list {
                align-items: center;
            }
            &__inputwrap {
                display:flex;
                flex-direction: row;
            }
            &__inputlabel {
                min-width:none;
            }
            &__inputblock {
                margin-top:0;
            }
            &__inputbtn {
                margin-top:0px;
            }
        }

    }

}


@media screen and (min-width:1680px) {
    
    
}


