


.mbmenu {

    .mbmenulist {
        display:none;
        position:fixed; z-index:-1;
        top:50%; left:50%;
        width:70%;
        opacity:0;
        transform:translate(-50%, -150%);
        &.on {
            display:block; z-index:40;
            animation:mbmenu 700ms 200ms forwards;
        }
    }

    &__linkwrap {
        padding:0 10px;
        max-height:70vh;
        color:#ffffff;
        overflow:auto;
    }

    &__link {
        display:block;
        margin:30px 0;
        font-size:5vw;
        text-align: center;
    }

        
    &__btn {
        display:flex;
        justify-content: center;
        align-items: center;
        position:fixed; z-index:30;
        top:20px; right:20px;
        width:15vw; height:15vw; 
        max-width:60px; max-height:60px;
        cursor:pointer;
        border-radius:100%; 
        background:$color_theme;
        box-shadow: 10px 10px 35px 0 rgba(100, 101, 110, 0.3);
        transition:transform 300ms;
        &.on {
            display:none;
        }
        svg {
            width:6vw; height:6vw;
            max-width:30px; max-height:30px;
        }
        path {
            fill:#ffffff;
        }
    }

    &__close {
        display:none;
        position:fixed; z-index:40;
        top:20px; right:20px;
        &.on {
            display:block;
        }
        svg {
            width:7vw; height:7vw;
        }
        path {
            fill:#ffffff;
        }
        
    }

    &__bg {
        display:none;
        position:fixed; z-index:-1;
        top:50%; left:50%;
        width:20vw; height:20vw;
        transform:scale(0) translate(-50%, -50%);
        transform-origin: top left;
        border-radius:100%;
        background:$color_theme;
        &.on {
            display:block; z-index:35;
            animation:mbmenu_bg 500ms forwards;
        }
    }

    &__title {
        display:none;
        position:fixed; z-index:-1;
        top:20px; left:20px;
        font-size:20px;
        font-weight:700;
        color:#ffffff;
        &.on {
            display:block;
            z-index:40;
            animation:mbmenu_title 700ms forwards;
        }
    }

    &__logout {
        display:none;
        position:fixed; z-index:-1;
        top:50px; left:20px;
        font-size:16px;
        font-weight:700;
        color:#ffffff;
        &.on {
            display:block;
            z-index:40;
            animation:mbmenu_logout 900ms forwards;
        }

    }

    &__provider {
        display:none;
        position:fixed; z-index:-1;
        bottom:20px; left:20px;
        font-size:15px;
        font-weight:700;
        line-height:1.3;
        color:#ffffff;
        &.on {
            display:block;
            z-index:40;
            animation:mbmenu_provider 700ms forwards;
        }
    }

}



@media screen and (min-width:768px) {

    .mbmenu {

        &__btn {
            width:10vw; height:10vw; 
            svg {
                width:5vw; height:5vw;
            }
        }
    
        &__close {
            svg {
                width:6vw; height:6vw;
            }
        }
    
        &__title {
            font-size:25px;
        }
    
        &__provider {
            font-size:20px;
            line-height:1.5;
        }
    
        &__link {
            margin:5vw 0;
            font-size:3.5vw;
        }


    }
    

}




@media screen and (min-width:1024px) {

    .mbmenu {
        &__btn {
            display:none!important;
        }
        &__bg {
            display:none!important;
        }
        &__title {
            display:none !important;
        }
        &__provider {
            display:none !important;
        }
        &__close {
            display:none!important;
        }
        .mbmenulist {
            display:none!important;
        }
    }

}


@keyframes mbmenu {
    from {
        opacity:0;
        transform:translate(-50%, -150%);
    }
    to {
        opacity:1;
        transform:translate(-50%, -50%);
    }
}


@keyframes mbmenu_bg {
    from {
        transform:scale(0) translate(-50%, -50%);
    }
    to {
        transform:scale(15) translate(-50%, -50%);
    }
}

@keyframes mbmenu_title {
    from {
        opacity:0;
        top:-100px;
    }
    to {
        opacity:1;
        top:20px;
    }
}

@keyframes mbmenu_logout {
    from {
        opacity:0;
        top:-100px;
    }
    to {
        opacity:1;
        top:50px;
    }
}

@keyframes mbmenu_provider {
    from {
        opacity:0;
        bottom:-100px;
    }
    to {
        opacity:1;
        bottom:20px;
    }
}




