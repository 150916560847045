.sales {
  display: flex;
  flex-direction: column;

  .pagination > li {
    margin-right: 1rem;
  }

  .selected .pagination__btn {
    color: #fff;
    background-color: #0cb4e2;
  }
}
