

$color_theme:#0cb4e2;

/* ::::::::: Search Section ::::::::: */

.search {
    position:relative;
    display:flex;
    flex-direction: row;
    align-items: stretch;
    
    &__bar {
        padding:0.625rem;
        width:12.5rem;
        font-size:0.875rem;
        background:#f1f3fa;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border:none;
        outline:none;
        transition: box-shadow 100ms;
        &::placeholder {
            font-size:13px;
            color: #8c93a1;
        }
        &::-webkit-input-placeholder {
            font-size:13px;
            color: #b0b6c7;
        }
        &:-ms-input-placeholder {
            font-size:13px;
            color: #b0b6c7;
        }
    }
    
    &__btn {
        padding:0 0.9375rem;
        height:100%;
        font-size:0.875rem;
        color:#ffffff;
        border:none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background:$color_theme;
        cursor:pointer;
        outline:none;
        transition:background 300ms;
        &:hover {
            background:#0599c2;
        }
    }
}

.datesearch {
    display:flex;
    align-items: stretch;
    margin-bottom:15px;

    &__block {

    }

    &__deco {
        display:flex;
        align-items: center;
        margin:0 5px;
        color:grey;
    }

    &__label {

    }

    &__input {
        padding:6px 10px;
        width:120px;
        color:#858796;
        border-radius:5px;
        border:1px solid #e3e6f0;
        outline:none;
        &.end {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
        &::placeholder {
            font-size:15px;
            color: #8c93a1;
        }
        &::-webkit-input-placeholder {
            font-size:15px;
            color: #b0b6c7;
        }
        &:-ms-input-placeholder {
            font-size:15px;
            color: #b0b6c7;
        }

    }

    &__search {
        padding:0 20px;
        height:100%;
        font-size:16px;
        color:#ffffff;
        background:$color_theme; 
        outline:none;
        border:none;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        cursor:pointer;
    }
}


@media screen and (min-width:768px) {
    

}

@media screen and (min-width:1024px) {

    .datesearch {
        margin:0;
    }
    
}

@media screen and (min-width:1680px) {


}



