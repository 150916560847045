$color_text:grey;
$color_border:grey;

.sidebarinput {
    display:none;
    position:fixed; z-index:100;
    top:0; left:0;
    width:100%;
    height:100vh; 
    background:white;
    transform:translate(-100%, 0);
    overflow:auto;
    &.on {
        display:block;
        animation: sidebarinput 0.3s forwards;
    }
        
    &__wrap {
        position:relative;
        padding:5px 0;
        height:100vh;
        overflow:auto;
    }
    
    &__close {
        position:absolute;
        top:10px; left:20px;
        cursor:pointer;
        svg {
            width:20px;
            height:20px;
        }
    }

    &__head {
        display:flex;
        justify-content: center;
        padding:50px 20px 20px 20px;
        border-bottom:3px solid $color_white;
        text-align: center;
        &.flexcolumn {
            flex-direction: column;
        }
    }

    &__logowrap {
        display: flex;
        justify-content: center;
        margin-bottom:20px;
    }

    &__logo {
        display:flex;
        justify-content: center;
        align-items: center;
        width:80px;
        height:80px;
        background:$color_theme;
        border-radius:10px;
        .svg_exchange {
            width:35px; 
            height:35px;
            path {
                fill:#ffffff;
            }
        }
    }

    &__title {
        font-size:15px;
        color:$color_text;
    }

    &__subtitle {
        margin-top:5px;
        font-size:30px;
        font-weight:700;
    }

    &__block {
        margin-top:30px;

    }

    &__inputwrap {
        display:flex;
        flex-direction: column;
        align-items: center;
    }

    &__inputlabel {
        font-size:17px;
        font-weight:600;
        color:$color_text;
    }

    &__input {
        margin-top:10px;
        padding:10px;
        width:80%;
        border:none;
        border-bottom:1px solid $color_border;
    }

    &__inputinfo {
        margin-top:10px;
        font-size:15px;
        color:$color_text;
    }

    &__btnwrap {
        display:flex;
        position:absolute;
        bottom:0; left:0; 
        width:100%; height:55px;
    }


    &__btn {
        width:50%;
        font-size:18px;
        color:#ffffff;
        background:$color_theme;
        border:1px solid $color_theme;
        cursor:pointer;
    }
        
 
}


@media screen and (min-width:768px) {

    .sidebarinput {
        width:60%;
    }
    
}


@media screen and (min-width:1024px) {

    .sidebarinput {
        width:350px;

        &__wrap {
            position:relative;
            padding:10px 0;
        }

        &__close {
            position:absolute;
            top:10px; left:20px;
            svg {
                width:20px;
                height:20px;
            }
        }

        &__head {
            display:flex;
            padding:45px 20px 20px 20px;
        }

        &__title {
            font-size:17px;
        }

        &__subtitle {
            margin-top:7px;
            font-size:23px;
        }

        &__btn {
            transition:background 300ms;
        }

        &__btn:hover {
            background:#ffffff;
            color:$color_theme;
        }

    }
    
}


@media screen and (min-width:1680px) {
    
}


@keyframes sidebarinput {
    from {
        transform:translate(-100%, 0);
    }
    to {
        transform:translate(0, 0);
    }
}