

.editmodal {

    .category {
        margin-top:30px;
        
        &__block {
            display:inline-block;
            margin:5px;
        }

        &__list {
            display:inline-block;
            padding:5px 15px;
            font-size:15px;
            color:#000000;
            background:white;
            cursor: pointer;
            border:2px solid #000000;
            transition:background 300ms;
        }

        &__chk {
            display:none;
            &:checked ~ label {
                color:#ffffff;
                border:2px solid $color_theme;
                background:$color_theme;
            }
        }

        &__selected {
            display:inline-block;
            margin-right:10px;
            padding:5px 15px;
            font-size:15px;
            color:#ffffff;
            background:$color_theme;
            border:2px solid $color_theme;
        }
    
    }

}
    


@media screen and (min-width:768px) {
    
}

@media screen and (min-width:1024px) {
        
    .editmodal {

        .category {
            margin-top:30px;
            
            &__block {
                display:inline-block;
                margin:10px 10px 0 0;
            }

            &__list {
                &:hover {
                    color:$color_theme;
                    border:2px solid $color_theme;
                }
            }
        }

    }

}

@media screen and (min-width:1680px) {
    

}




