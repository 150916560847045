@import 'styles/variables.scss';

$padding_cell: 15px 10px;
$padding_horizontal : 1.5625rem;
$color_table_grey1 : #e3e6f0;
$color_table_grey2:#858796;
$theme_font : "Noto Sans Kr";



/* :::::::::::::::::

Table 

::::::::::::::::: */

    .table_container {
        margin-top:30px;
        font-family: $theme_font;
        border: 1px solid $color_table_grey1;
        border-radius:20px;
        box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15);
    }
    
    .table_wrap {
        margin:20px;
        overflow-x:auto;
    }
    
    .tabletitle {
        margin-bottom:1.5625rem;
        padding:1.25rem $padding_horizontal;
        font-size:1.0625rem;
        color:$color_theme;
        background-color:#F8F9FC;
        border-bottom: 1px solid $color_table_grey1;
        border-top-left-radius: 20px;
        border-top-right-radius: 25px;
    }
    
    .tablesort {
        padding:0 $padding_horizontal 1rem $padding_horizontal;
    
        &__title {
            display:block;
            width:110px;
            color:$color_table_grey2;
        }
    
        &__sortWrap {
            display:flex;
            flex-direction: row;
            align-items: center;
            margin:0 10px 0 0;
        }
    
        &__dateDeco {
            margin:0 10px;
            color:#858796;
        }
    
    }
    
    
    
    /* ::::::::: Table Style ::::::::: */
    
    .datatable {
        min-width:100%;
        color:$color_table_grey2;
        table-layout: fixed;
        border-collapse: collapse;
        border: 1px solid $color_table_grey1;
        &.equalWidth {
            // width:100%;
            // .datatable__headItem {
            //     white-space: normal;
            // }
            // .datatable__bodyitem {
            //     white-space: normal;
            // }
        }
    
        td, th {
            border: 1px solid $color_table_grey1;
        }
    
        tr:nth-child(even){background-color: #f2f2f2;}
    
        &__head {
    
        }
    
        &__headItem {
            padding:$padding_cell;
            min-width:130px;
            font-weight:700;
            white-space: nowrap;
            &.center {
                text-align:center;
            }
            &.edit {
                min-width:50px;
            }
            
        }
    
        &__body {
    
        }
    
        &__bodyitem {
            position:relative;
            padding:$padding_cell;
            white-space: nowrap;
            
            &.center {
                text-align:center;
            }
    
            &.cursor {
                cursor:pointer;
            }
    
            &.comName {
                min-width:220px;
                overflow:hidden;
                transition:transform 300ms;
                cursor:pointer;
    
                &.edit {
                    .comName__btn:nth-child(1){
                        top:0; 
                    }
                    .comName__btn:nth-child(2){
                        top:0;
                    }
                }
            }

            .comName__btn {
                display:flex;
                justify-content: center;
                align-items: center;
                position:absolute;
                width:50%; height:100%;
                font-size:15px;
                color:#ffffff;
                background:$color_theme;
                border:none;
                transition:top 300ms;
                cursor:pointer;
                border:2px solid $color_theme;
                transition:transform 300ms background 500ms;
                &:hover {
                    color:$color_theme;
                    background:#ffffff;
                }
                &:nth-child(1){
                    top:-100%; left:0;
                }
                &:nth-child(2){
                    top:100%; right:0;
                }
               
            }
        }
    
        &__foot {
    
        }
    
        &__footItem {
            font-weight:700;
            white-space: nowrap;
            &.ghost {
                padding:$padding_cell;
                opacity:0;
            }
    
        }
    
        &__cancel {
            margin-left:5px;
            padding:5px 15px;
            color:#ffffff;
            background:$color_theme;
            border:none;
            cursor:pointer;
            outline:none;
            &:nth-of-type(1) {
                margin-left:0;
            }
        }

        &__cancel.dark {
            background:#848484;
        }
    
    }
    
    
    
    
    /* ::::::::: Table Navigation ::::::::: */
    
    .tableBottom {
        display:flex;
        flex-wrap:wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding:1.25rem $padding_horizontal;
    
        &__text {
            font-size:18px;
            color:$color_table_grey2;
        }
    
        &__excel {
            align-self: stretch;
            font-size:17px;
            font-weight:100;
            color:$color_table_grey2;
        }
    
        &__btn {
            padding: 0 0.625rem;
            height: 100%;
            font-size: 0.875rem;
            color: #ffffff;
            background: $color_theme;
            border: none;
            cursor: pointer;
            outline:none;
            border:2px solid $color_theme;
            transition:background 500ms;
            &:hover {
                color:$color_theme;
                background:transparent;
            }
        }
    
    }
    
    .tableNav {
        display:flex;
        flex-direction: row;
        align-items: center;
    
        &__btn {
            padding:10px 15px;
            font-size:15px;
            color:$color_theme;
            background:#ffffff;
            border:1px solid $color_table_grey1;
            border-right:none;
            cursor:pointer;
            white-space: nowrap;
            outline:none;
            transition:background 500ms;
            &.on {
                color:#ffffff;
                background:$color_theme;
            }
            &.last {
                border-right:1px solid $color_table_grey1;
            }
            &:hover {
                color:#ffffff;
                background:$color_theme;
            }
          
        }
    
        &__tabWrap {
            display:flex;
        }
    
    }
    
    
    @media screen and (min-width:768px) {
        
    
    }
    
    
    @media screen and (min-width:1024px) {

        .datatable {
            &__bodyitem.cellhover {
                cursor:pointer;
            }
            &__bodyitem.cellhover:hover {
                color:$color_theme;
                border-bottom:1px solid $color_theme;
                
            }
        }
    
    }
    
    
    @media screen and (min-width:1680px) {
    

    }
    
    





