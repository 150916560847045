@import 'styles/store/_variables.scss';


/* :::::::::::: 

Process 공통 

:::::::::::: */

.purchase {
    display:none;
    position:fixed; z-index:100;
    top:50%; left:50%;
    transform:translate(-50%, -50%) scale(0.6);
    width:100%;
    height:100vh;
    opacity:0;
    background:#ffffff;
    border-radius:10px;
    &.on {
        display:block;
        animation:store_purchase 0.3s forwards;
    }

    &__container {
        position:relative;
        width:100%; height:100%;
        border-radius:10px;
        overflow:hidden;
    }

    &__prev {
        position:absolute; z-index:50;
        top:30px; left:20px;
        cursor:pointer;
        svg {
            width:25px;
            height:25px;
        }
    }

    &__close {
        position:absolute; z-index:50;
        top:30px; right:20px;
        cursor:pointer;
        svg {
            width:25px;
            height:25px;
        }
    }

    &__title {
        padding:30px 0;
        font-size:25px;
        font-weight:900;
        text-align: center;
    }

    &__process {
        position:absolute;
        top:0; left:0;
        padding:0 20px;
        width:100%; height:100%;
        opacity:0.5;
        transform:translate(100%, 0%);
        transition:transform 300ms, opacity 300ms;
        &.on {
            transform:translate(0%, 0%);
            opacity:1;
        }
        &.prev {
            transform:translate(-100%, 0%);
        }
    }

    &__processinner {
       width:100%; height:100%;
       padding:50px 0 20px 0;
       overflow:auto;
    }
    
    &__calendarwrap {
        display:flex;
        justify-content: space-between;
        align-self: center;
        width:90%;
        margin:0 auto;
        bordeR:1px solid red;
    }

}




@media screen and (min-width:600px) {

    .purchase {
        width:90%;
        height:90vh;
        &__process {
            display:flex;
            justify-content: center;
            align-items: center;
        }
        
        &__processinner {
            height:auto;
            padding:20px 0;
        }

        &__process.calendar {
            .purchase__processinner {
                height:100%;
                padding:50px 0 20px 0;
            }
    
        }

    }

}


@media screen and (min-width:1024px) {

    .purchase {
        width:800px; 
        height:530px; 
        max-height:100vh;
  
        &__container {
            position:relative;
            width:100%; height:100%;
        }

        &__prev {
            svg {
                width:30px;
                height:30px;
            }
        }

        &__close {
            svg {
                width:30px;
                height:30px;
            }
        }

        &__title {
            padding:30px 0;
            font-size:30px;
        }

        &__process {
            display:block;
            padding:0 30px;
        }

        &__processinner {
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items:center;
            height:100%;
        }
        
        &__calendarwrap {

        }

    }

}


@media screen and (min-width:1680px) {

    
}




/* :::::::::::::::::::::::: 

스토어 정보 

:::::::::::::::::::::::: */


.purchase {

    .store {
        
        &__container {
            margin:0 auto;
            width:75%;
            &.textcenter {
                text-align: center;
            }
        }

        &__list {
            position:relative;
            display:block;
            margin:15px 2.5%;
            padding:15px 0;
            width: 100%;
            font-size:17px;
            color:#ffffff;
            background:$color_store_theme;
            text-align: center;
            cursor:pointer;
            border:2px solid $color_theme;
            transition:background 300ms;
            &.on {
                color:$color_theme;
                background:transparent;
            }

        }

        &__selected {
            position:absolute;
            top:50%; right:20px;
            transform:translate(0, -50%);
            svg {
                width:20px;
                height:20px;
                fill:$color_store_theme;
            }
        }

    }

}


@media screen and (min-width:600px) {
        
    .purchase {

        .store {
            
            &__container {
                margin:0 auto;
                width:75%;
                &.textcenter {
                    text-align: center;
                }
            }

            &__list {
                margin:15px auto;
                width:50%;
                font-size:20px;
            }

        }
    }

}


@media screen and (min-width:1024px) {

    .purchase {

        .store {
            
            &__container {
                margin:0 auto;
                width:75%;
                &.textcenter {
                    text-align: center;
                }
            }
    
            &__list {
                display:inline-block;
                margin:15px 2.5%;
                width: 45%;
                &:hover {
                    color:$color_theme;
                    background:transparent;
                }
            }
    
            &__selected {
                svg {
                    width:20px;
                    height:20px;
                }
            }
    
        }
    
    }
    
}

@media screen and (min-width:1680px) {

    
}



/* :::::::::::::::::::::::: 

날짜 정보 

:::::::::::::::::::::::: */



.purchase { 

    .DayPicker-Day {
        padding:10px;
    }

    .DayPicker-Day--before:not(.DayPicker-Day--outside) {
        color:white;
        background:rgba(0,0,0,0.2);
        border-radius: 0;
        cursor:auto;
    }
    
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background:$color_store_theme;
    }

    
    .calendar {

        &__wrap {
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            width:100%;
            margin:0 auto;
        } 

        .schedule__calendar  {
            text-align:center;
        }

        &__timewrap {
            flex:1 1;
            padding:10px 0;
            max-height:350px;
            overflow:auto;
        }

        &__submitwrap {
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            margin:20px auto 0 auto;
            width:90%;
        }

        &__selectedtime {

        }

        &__selectedinfo {
            display:block;
            text-align:center;
        }

        &__confirm {
            margin-top:10px;
            padding:10px 20px;
            font-size:15px;
            color:#ffffff;
            background:$color_store_theme;
            border:none;
            cursor:pointer;
        }

    }


    .timeselect {
        position:relative;

        &__title {
            padding:0 0 15px 0;
            font-size:20px;
            font-weight:700;
            text-align:center;
        }

        &__subtitle {
            font-size:20px;
            text-align: center;
        }

        &__listwrap {
            display:flex;
            flex-wrap:wrap;
        }

        &__list {
            width:28%;
            margin:5px 2%;
            padding:7px 0;
            text-align: center;
            cursor:pointer;
            border:1px solid black;
            transition:background 300ms;
            &.on {
                color:#ffffff;
                background:$color_store_theme;
                border:1px solid $color_store_theme;
            }
        }
        
    }

}


@media screen and (min-width:600px) {

    .purchase {

        .DayPicker-Day {
            padding:15px;
        }

    }

}


@media screen and (min-width:1024px) {

    .purchase { 

        .DayPicker-Day {
            padding:0.5em;
        }

        .calendar {
    
            &__wrap {
                display:flex;
                flex-direction: row;
                justify-content: space-between;
                width:90%;
            } 
    
            &__timewrap {
                flex:1 1;
                margin-left:30px;
                padding:10px 0;
                max-height:350px;
            }
    
            &__submitwrap {
                display:flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin:20px auto 0 auto;
                width:90%;
            }
    
            &__selectedtime {
    
            }

            &__selectedinfo {
                display:inline-block;
                text-align:left;
            }
    
            &__confirm {
                margin-top:0px;
                padding:10px 20px;
                font-size:15px;
            }
    
        }
    
    
        .timeselect {
            position:relative;
    
            &__title {
                padding:0 0 15px 0;
                font-size:20px;
            }

            &__subtitle {
                margin-top:10px;
                font-size:20px;
                opacity:0.6;
            }
    
            &__listwrap {
                display:flex;
                flex-wrap:wrap;
            }
    
            &__list {
                width:28%;
                margin:5px 2%;
                padding:7px 0;
                text-align: center;
                &:hover {
                    color:#ffffff;
                    background:$color_store_theme;
                    border:1px solid $color_store_theme;
                }
            }
            
        }
    
    }
    
    
}


@media screen and (min-width:1680px) {

    
}




/* :::::::::::::::::::::::: 

메모 설정 / 구입 및 장바구니

:::::::::::::::::::::::: */

.purchase { 

    .memo {

        &__textwrap {
            margin:0 auto;
            width:80%; 
            outline:none;
        }

        &__content {
            padding:10px;
            height:200px;
            font-size:15px;
            line-height:1.5;
            outline:none;
            border:2px solid grey;
            border-radius:10px;
            overflow:auto;
        }

        &__btnwrap {
            margin-top:30px;
            text-align: center;
        }
        
        &__confirm {
            padding:10px 25px;
            font-size:17px;
            color:#ffffff;
            background:$color_store_theme;
            border:none;
            cursor:pointer;
        }
    }


    .payment {

        &__infocontainer {
            margin:0 auto;
            width:90%;
        }

        &__infowrap {
            display:flex;
            flex-direction: column;
            .payment__infoblock {
                flex:1 1;
            }
        }

        &__infoblock {
            margin-top:20px;
            text-align: center;
        }

        &__infotitle {
            display:block;
            font-size:18px;
            font-weight:700; 
        }

        &__infocontent {
            display:block;
            margin-top:10px;
            font-size:16px;
            line-height:1.3;
            &.memo {
                height:80px;
                overflow:auto;
            }
        }

        &__btnwrap {
            display:flex;
            justify-content: center;
            flex-direction: row;
            margin-top:30px;
            text-align:center;
        }

        &__btn {
            display:inline-block;
            margin:0px 10px;
            padding:10px 20px;
            font-size:16px;
            color:#ffffff;
            background:$color_store_theme;
            border:none;
            cursor:pointer;
            border:2px solid $color_store_theme;
            transition:background 300ms;
        }
    }

}


@media screen and (min-width:600px) {


}


@media screen and (min-width:1024px) {

    .purchase { 

        .memo {

            &__textwrap {
                margin:0 auto;
                width:80%; 
            }

            &__content {
                padding:10px;
                height:200px;
                font-size:15px;
            }

            &__btnwrap {
                margin-top:30px;
            }
            
            &__confirm {
                padding:10px 25px;
                font-size:17px;
            }
        }


        .payment {

            &__infocontainer {
                margin:0 auto;
                width:90%;
            }

            &__infowrap {
                display:flex;
                flex-direction: row;
                margin-bottom:30px;
                .payment__infoblock {
                    flex:1 1;
                }
            }

            &__infoblock {
                margin-top:15px;
            }

            &__infotitle {
                display:block;
                font-size:18px;
            }

            &__infocontent {
                display:block;
                margin-top:10px;
                font-size:16px;
                &.memo {
                    padding:10px;
                    height:80px;
                }
            }

            &__btnwrap {
                margin-top:30px;
                text-align:center;
            }

            &__btn {
                margin:0 20px;
                padding:10px 30px;
                font-size:17px;
                &:hover {
                    color:$color_store_theme;
                    background:transparent;
                }
            }
        }

    }
}


@media screen and (min-width:1680px) {

    
}


@keyframes store_purchase {
    from {
        transform:translate(-50%, -50%) scale(0.6);
        opacity:0;
    }
    to {
        transform:translate(-50%, -50%) scale(1);
        opacity:1;
    }
}



