@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

@font-face { 
    font-family: 'Anton'; 
    font-weight: normal; 
    font-style: normal; 
    src: url('../assets/fonts/anton/anton-v12-latin-regular.woff') format('woff'),
         url('../assets/fonts/anton/anton-v12-latin-regular.ttf') format('truetype');
}

@font-face { 
    font-family: 'Noto Sans Kr'; 
    font-weight: normal; 
    font-style: normal; 
    src: url('../assets/fonts/notosans/noto-sans-kr-v12-latin_korean-500.woff') format('woff'),
         url('../assets/fonts/notosans/noto-sans-kr-v12-latin_korean-500.ttf') format('truetype');
}

@font-face { 
    font-family: 'Noto Sans Kr'; 
    font-weight: 700; 
    font-style: normal; 
    src: url('../assets/fonts/notosans/noto-sans-kr-v12-latin_korean-700.woff') format('woff'),
         url('../assets/fonts/notosans/noto-sans-kr-v12-latin_korean-700.ttf') format('truetype');
}

@font-face { 
    font-family: 'Noto Sans Kr'; 
    font-weight: 900; 
    font-style: normal; 
    src: url('../assets/fonts/notosans/noto-sans-kr-v12-latin_korean-900.woff') format('woff'),
         url('../assets/fonts/notosans/noto-sans-kr-v12-latin_korean-900.ttf') format('truetype');
}

