@import 'styles/variables.scss';


.speciallist {
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    margin-top:30px;

    &__info {
        display:flex;
        flex-direction:column;
        justify-content:center;
        text-align: center;
    }
    
    &__title {
        font-size:25px;
        font-weight:700;
    }

    &__subtitle {
        margin-top:15px;
        font-size:15px;
        color:#858796;
    }

    &__slide {
        margin-top:30px;
        width:100%;
    }

    &__navwrap {
        display:none;
    }

    &__nav {
        padding:7px 15px;
        color:color_common_theme;
        cursor:pointer;
    }

    &__nav.next {
        svg {
            width:30px; height:30px;
            transform:rotate(180deg);
        }
    }

    &__list {
        position:relative;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor:pointer;
    }

    // &__deco {
    //     position:absolute; 
    //     z-index:10;
    //     top:50%; left:50%; 
    //     transform:translate(-50%, -50%);
    //     width:90%; height:90%; 
    //     border:2px solid #ffffff;
    // }

    &__image {
        position:relative;
        width:180px;
        height:180px;
        @include bgImg;
        border-radius: 100%; 
        box-shadow: 2px 3px 5px 0 rgba(100, 101, 110, 0.5);
    }

    &__filter {
        position:absolute; 
        z-index:5;
        top:0; left:0; right:0; bottom:0; 
        background:rgba(0,0,0,0.1);
    }

    &__content {
        position:relative;  
        font-size:16px;
        text-align: center;
    }

    &__name {
        margin:20px 0 0 0;
        font-size:20px;
        font-weight:700;
    }

    &__desc {
        margin-top:10px;
        font-size:16px;
        color:#858796;
    }


    &__btnwrap {
        margin:16px 0 0 0;
    }

    &__btn {
        padding:7px 10px;
        font-size:14px;
        color:#ffffff;
        background:$color_theme;
        border:none;
        cursor: pointer;
    }


    .swiper {
        &-wrapper {
            padding:10px 0px;
        }
        &-slide {
            padding:30px 0px;
            border-radius: 20px;
            box-shadow: 0 0.15rem 1rem 0 rgba(100, 101, 110, 0.2);
        }
    }

}


@media screen and (min-width:768px) {

    .speciallist {
        flex-direction:row;
        justify-content:space-between;
        margin-top:30px;
        
        &__title {
            font-size:30px;
        }
        &__subtitle {
            font-size:20px;
        }
        &__slide {
            margin-top:30px;
            width:50%;
        }
        &__image {
           
        }
        &__navwrap {
            display:flex;
            justify-content: space-between;
            margin-top:20px;
        }
        &__name {
            font-size:20px;
        }
        
        &__list {
            position:relative;
        }

        .swiper {
            &-slide {
                padding:40px 10px;
            }
        }

    }

}


@media screen and (min-width:1024px) {

    .speciallist {
        margin-top:30px;
        &__title {
            font-size:25px;
        }
        &__subtitle {
            font-size:15px;
        }
        &__slide {
            margin-top:30px;
            width:70%;
            .swiper-slide {
                width:30%;
            }
        }
        &__image {
          
        }

        &__name {
            font-size:20px;
        }

        &__list {
            position:relative;
        }

        .swiper {
            &-wrapper {
                padding:10px 5px;
            }
            &-slide {
                padding:30px 10px;
            }
        }

    }

}


@media screen and (min-width:1400px) {

    .speciallist {
        margin-top:30px;
        &__list {
            position:relative;
        }
        &__title {
            font-size:27px;
        }
        &__image {
        
        }
        &__subtitle {
            font-size:17px;
        }
    }

}


@media screen and (min-width:1680px) {

    .speciallist {
        margin-top:30px;
        &__list {
            position:relative;
        }
        &__title {
            font-size:30px;
        }
        &__subtitle {
            font-size:20px;
        }
        &__name {
            font-size:22px;
        }
        &__desc {
            font-size:18px;
        }
        .swiper {
            &-slide {
                padding:50px 10px;
            }
        }
    }

}


