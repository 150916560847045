
@mixin revenueBlock {
    margin:30px auto 0 auto;
    padding: 20px 20px;
    width:90%;
    color:#ffffff;
    background:$color_theme;
    border-radius:10px;
}


 .revenue {

    &__head {
        margin:30px 0 0 0;
        padding:0 5px;
    }

    &__headblock {
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top:10px;
        &:nth-of-type(1){
            margin-top:0;
        }
    }

    &__headtitle {
        padding:5px 10px;
        min-width:80px;
        text-align: center;
        color:$color_theme;
        border:2px solid $color_theme;
        border-radius:30px;
    }

    &__headsubitle {
        color:grey;
    }

    &__block {
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin:20px auto 0 auto;
        padding: 20px 20px;
        width:90%;
        color:#ffffff;
        background:$color_theme;
        border-radius:10px;
        &:nth-of-type(1) {
            margin:0;
        }
    }
    
    &__icon {
        display:flex;
        justify-content: center;
        align-items: center;
        width:50px; 
        height:50px;
        background: $color_theme;
        border-radius:10px;

        &.total {
            svg {
                fill:none;
            }
            path {
                stroke:#ffffff;
            }
        }

        &.fees {
            svg {
                fill:#ffffff;
            }
        }

        &.result {
            svg {
                width:30px; height:30px;
            }
            path {
                fill:#ffffff;
                stroke-width: 4;
            }
        
        }
        svg {
            fill:#ffffff;
            animation:aniDownTop 700ms forwards;
            transform:rotate(90deg);
            width:30px; height:30px;
        }

    }

    &__btn {
        display:flex;
        justify-content: center;
        align-items: center;
        margin:20px auto 0 auto;
        padding:15px 20px;
        font-size:20px;
        font-weight:700;
        color:$color_theme;
        background:#ffffff;
        border:2px solid $color_theme;
        border-radius:10px;
        transition:300ms;
        &:hover {
            color:#ffffff;
            background:$color_theme;
        }
    }

    &--cancelbtn {
        color:$color_grey3;
        border-color:$color_grey3;
        &:hover {
            background:$color_grey3;
        }
    }



}